<template>
    <div class="" style="height:600px;overflow:scroll">
		<!-- {{list}} -->
		<div class="col-12 lg:col-6 xl:col-12"  v-for="(data,key) in list" :key="key" >
			<!-- <div  > -->
                <br>
			<div class="card mb-0" style="color:white;background-color:#173EA3;font-size:12px;line-height:0.9" >
				<div style="text-align:right">
					<b>Date:</b> {{D_M_Y_formatDate(data.date)}}
					<hr>
				</div>
				<div class="grid">
					<div class="col-2 md:col-2">
						<img style="width:70px;height:70px;  border-radius: 50%;" :src="(data.driver_photo?data.driver_photo:'https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o=')">
					</div>
					<div class="grid col-4 md:col-10">
						<div class="col-6 md:col-6">
							<span>Driver Name : </span><span style="text-align:right" v-if="data.driver_name"><b>{{data.driver_name.toUpperCase()}}</b></span>
						</div>
						<div class="col-6 md:col-6">
							<span>Vehicle Reg No : </span><b>{{data.vehicle_registrationNumber}}</b>
						</div>
							<div class="col-6 md:col-6">
							<span>Driver Mobile Number : </span><b>{{data.driver_mobile_number}}</b>
						</div>
						<div class="col-6 md:col-6">
							<span>Vehicle Model Name : </span><b>{{data.vehicle_model}}</b>
						</div>

					</div>
						
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			<!-- </div> -->
		
		</div>
		
		<div class="col-12" v-if="list==''" style="text-align:left">
            <br>	
			Data Not Found
		</div>
    </div>
</template>

<script>
export default {
props: ['list','user_id'],
methods:{
	D_M_Y_formatDate(date_parm)
    {
      if(date_parm)
      {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd+"-"+mm + "-" + yyyy ;
        // alert(dd)
        if(dd.toString()!="NaN")
        {
          //this.receipt_new_date = new_date;
          return new_date;
        }
        else
        {
          return date_parm;
        }
      }
    },
},
mounted(){
	
	// alert('here');
}
}
</script>