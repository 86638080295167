<template>
    <div class="grid" style="height:600px;overflow:scroll">
		<div class="col-12 lg:col-6 xl:col-12" >
			<div v-on:click="go_to_subject()" >
                <br>
			<div class="card mb-0" style="background-color:#173EA3" >
				<div class="flex justify-content-between mb-3">
					<div >
						<span class="block  font-medium mb-3">Drivers</span>
						<div class="text-900 font-medium text-xl">100</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-users text-blue-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			</div>
		</div><div class="col-12 lg:col-6 xl:col-12" >
			<div v-on:click="go_to_subject()" >
                <br>
			<div class="card mb-0" style="background-color:#173EA3" >
				<div class="flex justify-content-between mb-3">
					<div >
						<span class="block  font-medium mb-3">Drivers</span>
						<div class="text-900 font-medium text-xl">100</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-users text-blue-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			</div>
		</div><div class="col-12 lg:col-6 xl:col-12" >
			<div v-on:click="go_to_subject()" >
                <br>
			<div class="card mb-0" style="background-color:#173EA3" >
				<div class="flex justify-content-between mb-3">
					<div >
						<span class="block  font-medium mb-3">Drivers</span>
						<div class="text-900 font-medium text-xl">100</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-users text-blue-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			</div>
		</div><div class="col-12 lg:col-6 xl:col-12" >
			<div v-on:click="go_to_subject()" >
                <br>
			<div class="card mb-0" style="background-color:#173EA3" >
				<div class="flex justify-content-between mb-3">
					<div >
						<span class="block  font-medium mb-3">Drivers</span>
						<div class="text-900 font-medium text-xl">100</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-fw pi-users text-blue-500 text-xl"></i>
					</div>
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="">since last visit</span> -->
			</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {

}
</script>