<template>
  <div class="grid p-fluid">
    <div class="col-12 md:col-12"><Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /></div>
   
    <div class="col-12 md:col-4">
      
      <div class="card card-w-title" style="height: 800px">
        
        <div style="col-12 md;text-align:center">
          <img v-if="userdata.profile_image"
            :src="userdata.profile_image"
            style="width: 200px;height:200px; border-radius: 20%"
          />
          <img v-else
            src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
            style="width: 200px; border-radius: 20%"
          />
          <hr />
        </div>
        <div style="">
          <div class="grid">
            <!-- <div class="row"> -->
            <div class="col-4 md:col-4">Name</div>
            <div class="col-8 md:col-8">{{userdata.first_name+" "+userdata.last_name}}</div>
            <div class="col-4 md:col-4">Email Id</div>
            <div class="col-8 md:col-8">{{userdata.email_id}}</div>
            <div class="col-4 md:col-4">Mobile Number</div>
            <div class="col-8 md:col-8">{{userdata.mobile_number}}</div>
             <div class="col-4 md:col-4">DOB</div>
            <div class="col-8 md:col-8">{{userdata.dob}}</div>
           
            <div class="col-4 md:col-4">User Type</div>
            <div class="col-8 md:col-8">{{userdata.user_type}}</div>
            <div class="col-4 md:col-4">Address</div>
            <div class="col-8 md:col-8" v-if="userdata.permanent_address">{{userdata.permanent_address.address}} {{userdata.permanent_address.city}} {{userdata.permanent_address.state}} {{userdata.permanent_address.pincode}}</div>
            <div class="col-8 md:col-8" v-else></div>

            <!-- </div> -->
          </div>
          <!-- <div class="row"><div style="col-4 md;"><b>Name :</b></div>
                        <div style="col-8 md;">Prathamesh Tamboli</div></div>
                         <div class="row"><div style="col-4 md;"><b>Name :</b></div>
                        <div style="col-8 md;">Prathamesh Tamboli</div></div> -->
        </div>
      </div>
    </div>
    <div class="col-12 md:col-8">
      <div class="card card-w-title" style="height: 800px">
    <!-- {{userdata}} -->
          <tabs
    v-model="selectedTab"
  >
  <!-- {{tabs}} -->
    <tab
      class="tab"
      v-for="(tab, i) in tabs"
      :key="`t${i}`"
      :val="tab"
      :label="tab"
      :indicator="true"
    />
  </tabs>
    <tab-panels
    v-model="selectedTab"
    :animate="true"
  >
    <tab-panel >
      <!-- {{selected_tab}} -->
      <loading
            :active="isLoadingModel"
            :can-cancel="true"
            
            color="#173EA3"
            :is-full-page="fullPage"
          ></loading>
      <UserRides :list=profiledatta.userrides v-if="selectedTab=='Rides'"></UserRides>
      <UserFamilyMembers :list=profiledatta.userfamilymember v-if="selectedTab=='Family Members'"></UserFamilyMembers>
      
      <UserVehicles :user_type="userType" :list=profiledatta.driverVehicles v-if="selectedTab=='My Vehicles'"></UserVehicles>
      <!-- <UserDrivers v-if="selectedTab=='Drivers'"></UserDrivers> -->
    </tab-panel>
  </tab-panels>


        <!-- <h5>Profile</h5> -->
        <!-- <p>Steps and TabMenu are integrated with the same child routes.</p> -->
        <!-- <TabMenu  :model="nestedRouteItems" /> -->
        <!-- {{nestedRouteItems}} -->
        
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';
import UserRides from '../components/menu/UserRides'
import UserFamilyMembers from '../components/menu/UserFamilyMembers'
import UserVehicles from '../components/menu/UserVehicles'
import UserDrivers from '../components/menu/UserDrivers'
import BreadcrumbService from "../service/BreadcrumbService";
import apis from "@/apis";


export default {
  data() {
    return {
        active:"active",
        tabs : ['Rides','Family Members'],
        selectedTab: "Rides",
        userType:"Rider",
        userdata:[],
        user_id:'',
        profiledatta:[],  
        isLoadingModel:false,
        BreadcrumbService : null,
        breadcrumbHome: {},
        breadcrumbItems: [],
    };
  },
  async mounted(){
    
    if(localStorage.getItem('savedata'))
    {
       this.userdata=await JSON.parse(localStorage.getItem('savedata'));
       this.userdata.permanent_address=await JSON.parse(JSON.stringify(this.userdata.permanent_address));
       this.userType= await this.userdata.user_type;
        this.userdata.dob=this.D_M_Y_formatDate(this.userdata.dob);
        this.user_id= await this.userdata._id;
        // alert(this.user_id)
       if(this.userType=='Driver Owner' || this.userType=='Owner')
       {
        
         this.tabs.push("My Vehicles")
        //  this.tabs.push("Drivers")
       }
      if(this.userType=='Driver')
       {
        
        this.tabs.push("My Vehicles")
       }

      //  alert(this.userdata._id)
      // console.log(this.userdata.permanent_address.address)

    }

    await this.adminUserProfile();
  },
  async created()
  {
    this.BreadcrumbService = new BreadcrumbService();
    let Breadcrum_data = this.BreadcrumbService.userProfile()
    this.breadcrumbHome=Breadcrum_data.home;
    this.breadcrumbItems=Breadcrum_data.items;
    
  },
  methods: {
    adminUserProfile()
    {
          this.isLoadingModel=true;

      // alert(this.user_id)
      var data={user_id:this.user_id,user_type:this.userType};
      var promise = apis.adminUserProfile(data);
      promise
        .then(response => {
          
          this.isLoadingModel=false;
            this.profiledatta=response.data.data;
          }
        )
    },

    D_M_Y_formatDate(date_parm)
    {
      if(date_parm)
      {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd+"-"+mm + "-" + yyyy ;
        // alert(dd)
        if(dd.toString()!="NaN")
        {
          //this.receipt_new_date = new_date;
          return new_date;
        }
        else
        {
          return date_parm;
        }
      }
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    onContextRightClick(event) {
      this.$refs.contextMenu.show(event);
    },
  },
  components:{
        Tabs,
    Tab,
    TabPanels,
    TabPanel,
    UserRides,
    UserFamilyMembers,
    UserVehicles,
    UserDrivers
  }
};
</script>